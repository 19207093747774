@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  --primary-color: #b30000;
  --secondary-color: transparent;
  --text-color: #212121;
  --input-color: #212121;
  --input-background-color: #fcfcfc;
  --body-background-color: #fff;
  --white-color: #fff;
  --flat-color: #e0e0e0;

  /*========== Font and typography ==========*/
  --body-font: "Roboto", sans-serif;
  --font-regular: 400;
  --font-medium: 500;
  --font-bold: 700;
  --font-black: 900;
  --font-family-lato: "Lato", sans-serif;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: 1rem;
  background-color: var(--white-color);
  color: var(--text-color);
  line-height: 1.3;
}

*,
img:focus {
  outline: none;
}
.container-fluid {
  max-width: 1600px;
}
/* Common Styling */
button {
  background: none;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
}

.button-primary {
  background: var(--primary-color);
  color: var(--white-color);
  font-size: 18px;
  font-weight: var(--font-bold);
  text-align: center;
  margin-top: 32px;
  min-height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-primary:hover {
  background: #c80707;
  color: var(--white-color);
  box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
}
.min-vh-100 {
  min-height: 100vh;
}
.hide_icon {
  display: none;
}
.show_password,
.confPsw_show {
  display: inline-block;
  position: absolute;
  right: 20px;
  bottom: 13px;
  color: #d8d8d8;
  cursor: pointer;
}
.show_password.active .show_icon,
.confPsw_show.active .show_icon {
  display: none;
}
.show_password.active .hide_icon,
.confPsw_show.active .hide_icon {
  display: block;
}

/* Login page CSS */
.logo {
  width: 100%;
  height: auto;
}
.brand-wrapper {
  padding-bottom: 28px;
  border-bottom: 1px solid #d4d6dd;
}
.login-section-wrapper,
.signup-section-wrapper {
  width: 100%;
  margin: auto;
  max-width: 585px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3.25rem 0;
}
.login-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 398px;
  padding-top: 42px;
}
h1.signin-title {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 32px;
}

.btn-transparent {
  font-size: 18px;
  position: relative;
  background-color: transparent;
  border: none;
  padding: 9px 13px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
  min-height: 54px;
  font-weight: 900;
  color: var(--primary-color);
  inline-size: min-content;
}
.btn-transparent:hover {
  color: var(--primary-color);
  box-shadow: 0 2px 15px rgb(0 0 0 / 10%);
}
.btn-transparent span {
  position: absolute;
  left: 18px;
  top: 9px;
}

.divider {
  position: relative;
  width: 100%;
  padding: 32px 0 20px;
  display: flex;
  white-space: nowrap;
  text-align: center;
  border: 0px none;
}
.divider::before,
.divider::after {
  position: relative;
  width: 100%;
  border-top: 1px solid var(--flat-color);
  top: 50%;
  content: "";
  transform: translateY(50%);
}

.divider span {
  display: inline-block;
  padding-left: 9.6px;
  padding-right: 9.6px;
  color: var(--flat-color);
  font-size: 16px;
  text-transform: lowercase;
}

/* SignUp form CSS */
.signup-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 0;
  padding-top: 45px;
}

.form-group {
  margin-bottom: 25px;
}
.signup-wrapper label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
label,
span.fileSize_text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
  font-family: var(--body-font);
}
.form-control:focus {
  box-shadow: 0 0 0 2px rgb(179 0 0);
}
input.form-control,
select.form-control {
  display: block;
  height: 3.375rem !important;
  padding: 15px;
  background-color: var(--input-background-color);
  border: none;
  box-shadow: inset 0 1px 2px rgba(133, 133, 133, 0.3);
  color: var(--input-color);
  font-size: 18px;
  font-weight: var(--font-regular);
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}
a.forgot-password-link {
  display: inline-block;
  margin-top: 14px;
  color: var(--primary-color);
  font-weight: var(--font-medium);
}

/* without slider */
.slide-item {
  height: 100%;
  float: left;
  display: block;
  box-sizing: border-box;
  margin: 0px;
}
.slide-item img {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: medium none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.slider-container {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.slider-container img {
  width: 100%;
}
/* CSS For Slider */
.slide_item {
  height: 100%;
  float: left;
  display: block;
  overflow: hidden;
  inset: 0px;
  box-sizing: border-box;
  margin: 0px;
  position: relative;
}
.slide_item img {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: medium none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.slider-container {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.slider-container img {
  width: 100%;
}

.sliders {
  background-color: var(--white-color);
  margin-left: 0;
  transition: margin-left 1s;
  height: 100%;
}

.slide-nav {
  position: absolute;
  width: 100%;
  height: 20px;
  left: 0;
  bottom: 50px;
  text-align: center;
  z-index: 100;
}

.slide-nav-bt {
  width: 50px;
  height: 5px;
  margin: 0 5px;
  background-color: #939e95;
  border: solid 1px #939e95;
  border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.6s;
}
.slide-nav-bt.active {
  width: 100px;
  background-color: #fff;
  border: solid 1px #fff;
}

/* Side Navbar */
.navbar-vertical {
  position: -webkit-sticky;
  position: sticky;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  z-index: 1019;
  top: 4.3125rem;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  max-width: 20rem;
  border-radius: 38px;
  border: none;
}
.navbar-collapse {
  height: 100%;
}
.navbar-vertical-content.scrollbar {
  height: 100%;
  overflow: hidden scroll;
  padding: 52px 65px;
  width: 100%;
  scrollbar-width: none;
}
.scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.side-bar-header {
  padding: 31px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}
.img-avatar {
  display: inline-block !important;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.user-avatar span {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: 400;
}
/* Toggle Button  */
.navbar-toggler-humburger-icon {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.3125rem;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background: none;
  position: absolute;
  top: 50%;
  left: 30px;
  margin: -15px 0 0;
}
.navbar-toggle-icon {
  position: relative;
  height: 0.125rem;
  width: 1.25rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: block;
  top: -0.3125rem;
  padding-right: 0;
}
.navbar-toggle-icon .toggle-line {
  display: block;
  height: 100%;
  border-radius: 0.25rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: #5e6e82;
}
.navbar-toggle-icon::after,
.navbar-toggle-icon::before {
  content: "";
  position: absolute;
  height: 0.125rem;
  background-color: #5e6e82;
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 0.25rem;
  width: 100%;
}
.navbar-toggle-icon::before {
  top: 0.3125rem;
}
.navbar-toggle-icon::after {
  top: 0.625rem;
}
.navbar-toggler-humburger-icon:hover,
.navbar-toggler-humburger-icon:focus {
  background-color: #d8e2ef;
  display: flex;
}
.canvas-open {
  overflow: hidden;
}
.opacity_bg {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background: #fff;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  display: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.canvas-open .opacity_bg {
  width: 100%;
  opacity: 0.95;
  visibility: visible;
  display: block;
  z-index: 99;
}
button.btn-close-menu {
  -webkit-appearance: none;
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
/* Navbar Styling */
p.menu-text {
  color: #272727;
  font-size: 16px;
  font-weight: var(--font-bold);
  text-transform: uppercase;
  letter-spacing: 3px;
}
.nav-link-text {
  margin-left: 19px;
}
span.nav-link-icon {
  line-height: 0;
}
span.nav-link-icon svg {
  width: 25px;
}
.navbar-light .navbar-nav .nav-link {
  color: var(--text-color);
  font-size: 18px;
  padding: 1rem 0;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--primary-color);
}
.nav-link.active,
.nav-link.active span.nav-link-icon,
.nav-link.active .nav-link-text {
  color: var(--primary-color);
}
.nav-link.active span.nav-link-icon svg > *,
.navbar-light .navbar-nav .nav-link:hover svg > * {
  fill: var(--primary-color);
}
.navbar-light .navbar-nav .nav-link:hover path#Path_110,
.nav-link.active path#Path_110 {
  fill: var(--primary-color);
}
.navbar-light .navbar-nav .nav-link:hover path#Path_111,
.nav-link.active path#Path_111 {
  fill: none;
  stroke: var(--primary-color);
}

/* Dashboard CSS */
#main-content {
  font-family: var(--font-family-lato);
  color: var(--text-color);
}
#main-content::before {
  content: "";
  position: fixed;
  background: url("./images/background_body.jpg") no-repeat center center/cover;
  width: 100%;
  height: 100vh;
  z-index: -1;
  filter: blur(10px);
}

/* Content styling */
.content {
  padding: 4.3125rem 0 2.3125rem;
}

.card-border-radius {
  border-radius: 38px;
  overflow: hidden;
  border: none;
}
.card-border-radius .card-header {
  text-align: center;
  background: none;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  padding: 41px;
}
.card-header .logo {
  max-width: 528px;
}

/* Table section CSS */
.table-responsive {
  margin-top: 1rem;
}
#dataTable_files th {
  border: none;
}
#dataTable_files td,
#dataTable_files th {
  font-family: var(--font-family-lato);
  font-weight: var(--font-medium);
  padding: 1.813rem 0.75rem;
  vertical-align: middle;
  border-top: none;
  border-bottom: 1px solid #dee2e6;
  font-size: 24px;
}
#dataTable_files td {
  font-size: 21px;
  font-weight: var(--font-regular);
}
td svg > * {
  fill: #000;
}
.table_nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 0;
}
a.table_nav-btn {
  height: 5px;
  width: 30px;
  border-radius: 10px;
  background: var(--primary-color);
  margin: 0 9px;
  opacity: 15%;
  transition: all 0.6s;
}
a.table_nav-btn.active {
  opacity: 1;
}

::placeholder {
  color: var(--input-color);
  font-weight: var(--font-regular);
  opacity: 1; /* Firefox */
}

.btn-close-menu {
  display: none;
}

/* Update Files folder */
#upload_files_form,
#edit_profile_form {
  width: 100%;
  max-width: 684px;
  margin: 0 auto;
  padding-bottom: 4rem;
}
.card-body h2 {
  font-size: 33px;
  text-align: center;
  font-family: var(--body-font);
  font-weight: var(--font-bold);
  margin: 3.75rem 0 1.875rem;
}
.card-body h2.settingTitle {
  margin-top: 1.875rem;
}
.card-body p {
  text-align: center;
  font-size: 20px;
  font-family: var(--body-font);
  font-weight: var(--font-bold);
  margin-bottom: 1.25rem;
}
.card-body .button-primary {
  padding: 0.938rem 0.75rem;
}
.arrow-down {
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #818181;
  position: absolute;
  right: 15px;
  bottom: 20px;
  transition: all 0.3s;
}
.form-control.active + .arrow-down {
  transform: rotate(180deg);
}

/* FOrm Checkbox  */
.fm-new {
  position: relative;
  display: block;
  width: 50px;
}
.form-check-input {
  position: absolute;
  height: 100%;
  opacity: 0;
  -webkit-appearance: checkbox;
}
.form-check-label {
  cursor: pointer;
}
/* latest */
.fm-check-input + label::before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 19px;
  height: 19px;
  background: white;
  border: 2px solid #000000;
  border-radius: 5px;
}
.fm-check-input:checked + label::before {
  background: #000;
}
.fm-check-input:checked + label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 4px;
  display: inline-block;
  transform: rotate(45deg);
  height: 11px;
  width: 18px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}
/* end */

.form-check-input + label::before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 19px;
  height: 19px;
  background: white;
  border: 2px solid #000000;
  border-radius: 5px;
}
.form-check-input:checked + label::before {
  background: #000;
}
.form-check-input:checked + label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 4px;
  display: inline-block;
  transform: rotate(45deg);
  height: 11px;
  width: 7px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

/* Clients Section CSS */
#clients {
  padding-bottom: 2.7rem;
}
.client_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 702px;
  width: 100%;
  margin: 4rem auto 0;
  flex-wrap: wrap;
  font-family: var(--body-font);
  word-break: break-all;
}
.client_item {
  width: 100%;
  max-width: 202px;
  margin-bottom: 3rem;
}
.client_item a {
  font-family: var(--body-font);
  font-weight: var(--font-regular);
  padding: 0.938rem 0.625rem;
  display: block;
  border: 1px dashed #707070;
  outline-offset: 5px;
  border-radius: 23px;
  color: #000;
  font-size: 21px;
  min-width: 0px;
}
.client_item a:hover,
.client_item a:focus {
  background: var(--primary-color);
  color: var(--white-color);
}

/* Manage Client Styling */
.card-body .btn-transparent {
  font-family: var(--font-family-lato);
  font-weight: 500;
  max-width: 179px;
  padding: 9px 13px 9px 37px;
  min-height: 50px;
  border-radius: 22px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
  margin-top: 2.938rem;
  inline-size: min-content;
}
.card-body .btn-transparent img {
  height: 23px;
  width: auto;
}
#uploadSettingsForm label,
span.fileSize_text {
  font-family: var(--body-font);
  font-size: 21px;
  font-weight: bold;
  margin: 0;
}
#uploadSettingsForm .form-group {
  display: flex;
  align-items: center;
}
#uploadSettingsForm input {
  width: auto;
  margin: 0 1rem;
}
.card-body h3 {
  font-size: 20px;
  font-weight: var(--font-bold);
  text-align: center;
  margin: 0;
  font-family: var(--body-font);
  padding-top: 4rem;
}
.card-body p.tagline {
  font-size: 21px;
  font-weight: lighter;
  color: var(--input-color);
  margin-bottom: 30px;
}
.checkbox_group {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: var(--body-font);
  font-weight: var(--font-bold);
}
.fileSettings {
  max-width: 488px;
}
a#saveSettings.button-primary {
  max-width: 684px;
  margin: 6rem auto 3.5rem;
}

/* CSS Media Queries */
@media (max-width: 1024px) {
  .navbar-vertical-content.scrollbar {
    padding: 52px 29px;
  }
  #upload_files_form,
  #edit_profile_form {
    max-width: 520px;
  }
  .button-primary {
    max-width: 540px !important;
  }
  .fileSettings {
    max-width: 50%;
  }
  #uploadSettingsForm input {
    width: 90%;
  }
}
@media (max-width: 992px) {
  .btn-close-menu {
    display: block;
  }
  .navbar-toggler-humburger-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .navbar-vertical-content.scrollbar {
    padding: 52px 52px;
  }
  .navbar-left {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    max-width: 20rem;
    height: 100%;
    padding: 0;
    -webkit-box-shadow: 0 0.5rem 1.875rem rgb(0 0 0 / 15%);
    box-shadow: 0 0.5rem 1.875rem rgb(0 0 0 / 15%);
    -webkit-transform: translateX(-100%) translateY(0) translateZ(0);
    transform: translateX(-100%) translateY(0) translateZ(0);
    -webkit-overflow-scrolling: touch;
    transition: -webkit-transform 0.26s ease-in;
    transition: transform 0.26s ease-in;
    transition: transform 0.26s ease-in, -webkit-transform 0.26s ease-in;
  }
  .navbar-left.show {
    -webkit-transform: translateX(0) translateY(0) translateZ(0);
    transform: translateX(0) translateY(0) translateZ(0);
  }
  .navbar-vertical {
    height: 100vh;
    border-radius: 0;
    max-width: 25rem;
  }
}

@media (max-width: 767px) {
  ul#navbarVerticalNav {
    padding-bottom: 7rem;
  }
  #dataTable_files td {
    min-width: 130px;
    white-space: nowrap;
  }
  .card-border-radius .card-header {
    padding: 20px;
  }
  .logo {
    width: 80%;
  }

  #uploadSettingsForm .form-group {
    display: block;
  }
  #uploadSettingsForm label {
    margin-bottom: 14px;
  }
  #uploadSettingsForm input {
    margin: 0;
    width: 90%;
  }
  .card-body h3 {
    padding-top: 2rem;
  }
  .fileSettings {
    margin-bottom: 3rem;
    max-width: 100%;
  }
  .form-check {
    padding-left: 0;
  }
  .client_section {
    justify-content: space-around;
  }
  .client_item {
    margin: 0 1rem 3rem;
  }
  a#saveSettings.button-primary {
    margin-top: 4rem;
  }
}

@media (max-width: 568px) {
  .navbar-toggler-humburger-icon {
    left: 20px;
  }
  .logo {
    margin-left: 30px;
  }
}

/* Webkit styling for input placeholder */
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--input-color);
  font-weight: var(--font-regular);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--input-color);
  font-weight: var(--font-regular);
}
:-moz-placeholder {
  /* Firefox 18- */
  color: var(--input-color);
  font-weight: var(--font-regular);
}

/**** react-paginate ****/
.paginationBttns {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #efebea;
  color: black;
  cursor: pointer;
}

.paginationBttns a:hover {
  background-color: #f8951d;
  color: black;
}

.paginationActive a {
  background-color: #f8951d;
  color: black;
}

.sortbtn-dashboard {
  background-color: #f8951d;
  color: black;
  padding: 2px;
  border-radius: 25px;
  height: 30px;
  width: 60px;
  cursor: pointer;
}

/**** end of react paginate **/

/**** table center****/
.tb-center {
  margin-left: auto;
  margin-right: auto;
}

.client_search {
  display: block;
  border: 1px dashed #707070;
  outline-offset: 5px;
  border-radius: 13px;
  color: #000;
  font-size: 10px;
}
